@import './src/assets/scss/config';

.choose-language {
  text-align: center;
  margin-bottom: map-get($spacers, 3);

  .k-dropdown {
    i {
      @include font-size(17px);
      margin-right: map-get($spacers, 2);
    }
  }
}
/*
.choose-language {
  margin-top: 40px;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);

  &.k-dropdown {
    .k-dropdown-wrap {
      background-color: transparent;
      border-color: #f0f0f0;

      &.k-state-focused {
        background-color: #f9f9f9;
        border-color: #efefef;
        box-shadow: 0 0 10px 0 rgba($color: #000, $alpha: 0.05);
      }

      &:hover {
        background-color: #f9f9f9;
        border-color: #efefef;
      }
    }

    .k-input {
      span {
        @include font-size(13px);
        text-align: center;
        width: 100%;
      }
    }
  }
}

.k-list-container.k-animation-container-shown {
  .k-popup {
    background-color: #f9f9f9;
    border-color: #e9e9e9;
    border-radius: 2px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    padding: 0;

    .k-list-scroller {
    }
  }
}

.k-list-container {
  border-radius: 2px;

  .k-popup {
    border-color: #e9e9e9;
  }

  .k-list {
    .k-item {
      @include font-size(13px);

      &:first-child {
        border-radius: 2px 2px 0 0;
      }

      &:last-child {
        border-radius: 0 0 2px 2px;
      }

      &.k-state-selected {
      }
    }

    .k-item:not(.k-state-selected) {
      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
*/
