.new-message {
  .k-form {
    max-width: 600px;

    > span {
      margin-top: 0.75rem;

      &.k-textarea {
        margin-top: 2.25rem;
        min-height: 150px;
      }
    }
  }

  button {
    margin-top: 2rem;
  }
}
