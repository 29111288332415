@import './src/assets/scss/config';

.document-modal-dialog {
  .k-window-titlebar {
    align-items: baseline;

    .document-category {
      @include font-size(14px);
      font-style: italic;
    }

    h5 {
      margin-bottom: 0;
    }

    .document-contacts {
      @include font-size(14px);

      span {
        &::after {
          content: '\00B7';
          display: inline-block;
          padding: 0 5px;
        }

        &:last-child::after {
          display: none;
        }
      }
    }
  }

  .k-window-title {
    display: block;

    &::before {
      content: none;
    }
  }

  iframe {
    border: 0;
  }

  object {
    width: 100%;
    height: calc(100vh - 15rem);
  }

  .k-actions.k-actions-stretched {
    align-items: flex-end;

    .k-button {
      display: block;
      flex-grow: unset;
      flex: unset;
      max-width: none;
    }

    .icon-label {
      @include font-size(11px);
      display: block;
    }
  }

  &.k-dialog-wrapper {
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
  }
}
