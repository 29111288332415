@import '../../../assets/scss/config';

.task-action {
  ol.attachments-list {
    margin: map-get($spacers, 4) 0 map-get($spacers, 3);
  }

  .system-text {
    font-style: italic;
  }
}
