@import './src/assets/scss/config';

.documents {
  header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h1 {
      margin: 0;
    }

    .filters-btn {
      margin-left: auto;

      i {
        margin-left: map-get($spacers, 2);
      }
    }
  }
}
