@import '../../../assets/scss/config';

.submission-request-card {
  > a {
    color: #101010;
    display: flex;
    // align-items: center;

    &:hover {
      color: #101010;
      text-decoration: none;

      h2 {
        color: #007bff;
        text-decoration: underline;
      }
    }
  }

  &-icon {
    padding: 15px 20px 15px 0;

    i {
      @include font-size(32);
    }
  }

  &-body {
    padding: 10px 20px 10px 0;

    h2 {
      color: #007bff;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}
