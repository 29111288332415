@import './src/assets/scss/config';

.action-request {
  display: block;
  text-decoration: none;
  margin-bottom: map-get($spacers, 3);

  .tagline {
    margin-bottom: map-get($spacers, 2);
  }

  figure {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: map-get($spacers, 3) 0 map-get($spacers, 3) map-get($spacers, 3);

    .action-request-image {
      width: 80px;
    }
  }

  .k-card-subtitle {
    ul {
      margin-bottom: 0;
    }

    li {
      margin: 0;

      &::after {
        content: '·';
        padding: 0 map-get($spacers, 1);
      }

      &:last-child::after {
        display: none;
      }
    }
  }

  .badge {
    margin-right: map-get($spacers, 1);
  }
}
