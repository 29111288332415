@import './src/assets/scss/config';

.k-listview.documents-list {
  .list-item {
    display: flex;
    border-bottom: $border-color solid $border-width;
    align-items: center;

    &:last-child {
      border-bottom: 0;
    }

    .list-item-checkbox {
      padding: ($grid-gutter-width / 2) 0 ($grid-gutter-width / 2)
        ($grid-gutter-width / 2);
    }

    .list-item-document {
      padding: $grid-gutter-width / 2;
      flex-grow: 1;
      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
      }

      .list-item-document-name {
        @include media-breakpoint-up(md) {
          padding-right: $grid-gutter-width / 2;
        }
      }

      .list-item-document-category {
        @include font-size(14px);
        font-style: italic;
      }

      .list-item-document-contacts {
        @include font-size(14px);

        span {
          display: inline-block;

          &::after {
            content: '\00B7';
            display: inline-block;
            padding: 0 5px;
          }

          &:last-child::after {
            display: none;
          }
        }
      }

      .list-item-date {
        @include media-breakpoint-up(md) {
          margin-left: auto;
        }
      }
    }
  }
}
