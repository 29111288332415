@import './src/assets/scss/config';

.user-info {
  text-align: center;
  margin-bottom: map-get($spacers, 5);
}

.global-navigation {
  margin-bottom: map-get($spacers, 5);

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    border-top: $gray-400 solid 1px;
  }

  li {
    margin: 0;
    border-bottom: $gray-400 solid 1px;
  }

  a {
    display: flex;
    align-items: center;
    color: $body-color;
    text-decoration: none;
    padding: map-get($spacers, 2) 0;

    &:hover,
    &.active {
      color: $body-color;
      text-decoration: none;

      span:not(.badge) {
        text-decoration: underline;
      }
    }
  }

  .badge {
    @include font-size(10px);
    background-color: #0d6efd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    margin-left: 5px;
    height: 24px;
    width: 24px;
    text-decoration: none;
    vertical-align: middle;
  }
}
