@import '../../assets/scss/config';

.workflow-task {
  text-decoration: none;

  .workflow-task-body {
    display: flex;
    align-items: center;
  }

  .task-text {
    margin-right: auto;

    .subtitle-system-text {
      font-style: italic;
    }
  }

  .task-status-icon {
    margin-left: $grid-gutter-width;
    flex-shrink: 0;
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid $warning;
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
    }

    &.hidden-icon {
      display: none;
    }

    /* quarter */
    &.quarter-circle::before {
      top: 0;
      right: 0;
      width: 50%;
      height: 50%;
      background: $warning;
    }

    /* half */
    &.half-circle::before {
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: $warning;
    }

    /* three-quarters */
    &.three-quarters-circle::before {
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      background: $warning;
    }

    &.three-quarters-circle::after {
      bottom: 0;
      right: 0;
      width: 100%;
      height: 50%;
      background: $warning;
    }

    /* check-mark */
    &.check-mark {
      border-color: $success;
    }

    &.check-mark::before {
      top: 4px;
      left: 9px;
      width: 8px;
      height: 15px;
      border-bottom: 2px solid $success;
      border-right: 2px solid $success;
      transform: rotate(45deg);
    }
  }
}
