@import './src/assets/scss/config';

.layout {
  main {
    .main-section {
      &.public {
        text-align: center;

        @include media-breakpoint-up(md) {
          text-align: left;
        }

        .k-button {
          margin-top: 15px;
        }
      }
    }
  }
}

.action-required {
  &.hidden {
    display: none;
  }
}

.alert-upload,
.alert-signing {
  display: flex;

  &.alert {
    margin-bottom: 10px;
    padding: 0;
  }

  .icon {
    display: flex;
    padding: 25px 20px;

    i {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 25px;
      min-width: 28px;
    }
  }

  .text {
    padding: 0.75rem 1.25rem;
  }
}
