@import '../../../assets/scss/config';

.conversations-list {
  header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    h1 {
      margin: 0;
    }

    .k-button {
      color: inherit;
      margin-left: auto;
    }
  }

  &.k-listview {
    margin-top: calc(1.5 * 1rem);

    .list-item {
      display: flex;
      border-bottom: $border-color solid $border-width;
      align-items: center;

      &:last-child {
        border-bottom: 0;
      }

      .list-item-conversation {
        padding: $grid-gutter-width / 2;
        flex-grow: 1;
        @include media-breakpoint-up(md) {
          display: flex;
          align-items: center;
        }

        a {
          display: inline-block;
          margin-bottom: 0;
          padding: 0;

          @include media-breakpoint-up(md) {
            padding: 0 0 0 15px;
          }

          &.has-new-message {
            padding: 0 0 0 15px;
            position: relative;

            &::before {
              background-color: #0d6efd;
              border-radius: 50%;
              content: '';
              display: inline-block;
              height: 8px;
              width: 8px;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translate(0, -50%);
            }
          }
        }

        .list-item-conversation-name {
          @include media-breakpoint-up(md) {
            padding-right: $grid-gutter-width / 2;
          }
        }

        .list-item-date {
          @include media-breakpoint-up(md) {
            margin-left: auto;
          }
        }
      }
    }
  }

  .back-link {
    @include font-size(12px);
  }
}
