@import './src/assets/scss/config';

.filters {
  background-color: $body-bg;
  display: none;
  height: 100vh;
  overflow-y: auto;
  padding: ($grid-gutter-width * 2) ($grid-gutter-width / 2) 0;
  width: 100%;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;

  @include media-breakpoint-up(md) {
    background-color: transparent;
    margin-top: 20px;
    overflow: visible;
    padding: 0;
    height: auto;
    width: auto;
    z-index: 1;
    position: relative;
    top: auto;
    left: auto;
  }

  &.on {
    display: block;
  }

  .close-btn {
    position: absolute;
    top: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .row {
    .col {
      margin-bottom: map-get($spacers, 3);
    }
  }

  .k-textbox-container {
    width: 100%;
  }

  .k-dropdown {
    .k-dropdown-wrap {
      background-color: #fff;
      border-color: #ced4da;

      &.k-state-focused {
        background-color: #fff;
        border-color: #86b7fe;
        color: #495057;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
      }

      .k-select {
        background-color: #e4e7eb;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
          border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
      }

      &:hover {
        background-color: #fff;
        border-color: #ced4da;

        .k-select {
          background-color: #ced3db;
        }
      }
    }
  }

  .k-textbox-container {
    width: 100%;

    > .k-label {
      transform: scale(0.9);
    }

    &:focus-within {
      > .k-label {
        transform: scale(0.9);
      }
    }

    &.k-state-empty {
      > .k-label {
        top: 0;
        left: 0;
        transform: scale(0.9);
      }
    }
  }

  .k-combobox {
    .k-dropdown-wrap {
      background-color: #fefefe;
    }

    .k-clear-value {
      height: auto;
    }
  }

  .k-list-container .k-nodata {
    min-height: auto;
  }
}

.filter-buttons {
  margin: 30px 0;

  @include media-breakpoint-up(md) {
    margin: 25px 0 10px;
  }

  button {
    width: 100%;
  }
}

// Styling for ComboBox DropDown (k-animation-container) - Remove box-shadow around first item in dropdown list
.k-animation-container {
  .k-list .k-item.k-state-focused {
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    &.k-state-selected {
      box-shadow: none;
    }
  }
}
