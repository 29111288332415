@import './src/assets/scss/config';

.task-messages {
  &.disabled {
    .k-message-box {
      display: none;
    }
  }

  .k-chat {
    height: auto;
    margin: 0;
    max-height: 600px;
    min-height: 150px;
    max-width: none;
  }
}

.closed-conversation {
  @include font-size(14px);
  margin-top: 1rem;
  padding: 1rem;

  span {
    display: inline-block;
  }
}
