@import './src/assets/scss/config';

.document-preview {
  .download-button {
    text-align: right;
    position: fixed;
    bottom: 15px;
    right: 15px;

    .k-button.k-primary {
      @include font-size(25px);
      border-radius: 50%;
      box-sizing: content-box;
      line-height: 1;
      padding: 20px;
      height: 25px;
      width: 25px;
    }
  }
}
