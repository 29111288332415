.idle-time-modal {
  .k-window {
    border-radius: 2px;
  }

  .k-actions.k-actions-stretched {
    .k-button {
      border-radius: 0 0 0 2px;

      &.k-primary {
        border-radius: 0 0 2px;
      }
    }
  }
}
