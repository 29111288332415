@import './src/assets/scss/config';

.global-footer {
  background: $gray-800;
  color: $white;
  // make flex child stick to bottom of container with top margin auto
  margin-top: auto;
  padding: map-get($spacers, 2) 0;

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      color: shift-color($white, $link-shade-percentage);
    }
  }

  .global-footer-navigation {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .global-footer-nav {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(md) {
      justify-content: flex-end;
    }

    li {
      margin: 0 map-get($spacers, 2) map-get($spacers, 2) map-get($spacers, 2);
      padding: 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 map-get($spacers, 3);
      }
    }
  }

  .global-footer-text {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }
}
