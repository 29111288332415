@import './src/assets/scss/config';

.global-header {
  background: $gray-800;
  color: $white;
  padding: map-get($spacers, 3) 0;

  .container {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .logo,
  .btn-menu {
    color: $white;
    display: flex;
    align-items: center;
  }

  .logo {
    img {
      width: 150px;
    }
  }

  .btn-menu {
    margin-left: auto;
    padding: 0;
    text-decoration: none;
    box-shadow: none;

    &:hover {
      color: shift-color($white, $link-shade-percentage);
    }

    &:active:focus,
    &:focus {
      outline: none;
      box-shadow: none;
    }

    i {
      @include font-size(22px);
      margin-left: map-get($spacers, 2);
      position: relative;

      &.notification-badge {
        &::after {
          background-color: #0d6efd;
          border: 2px solid $gray-800;
          border-radius: 50%;
          content: '';
          display: inline-block;
          height: 11px;
          width: 11px;
          position: absolute;
          left: -1px;
          top: 2px;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
